<template lang="pug">
    .main-wrapper.salvar-perguntas
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            router-link(:to='{ path: "/banners" }') Banners /
                            b {{ model.id ? 'Editar' : 'Adicionar' }}

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } banner`")
                        .p-grid.p-fluid.p-align-end
                            .p-col-6
                                label.form-label Título: *
                                InputText.p-inputtext-lg(type='text' v-model="model.title" required)

                            .p-col-6
                                label.form-label Subtítulo:
                                InputText.p-inputtext-lg(type='text' v-model="model.subtitle")

                            .p-col-6
                                label.form-label Link:
                                InputText.p-inputtext-lg(type='text' v-model="model.link" :disabled="model.show_mobile")

                            .p-col-6
                                label.form-label Texto do botão:
                                InputText.p-inputtext-lg(type='text' v-model="model.text_button" :disabled="model.show_mobile")

                            .p-grid.p-col-12
                                .p-col-2
                                    div
                                        label.form-label Cor do botão:
                                        ColorPicker(v-model="model.color_button")

                                    div(style="width: 196px;")
                                        .p-inputgroup.my-1
                                            span.p-inputgroup-addon #
                                            InputText.p-inputtext-lg(type='text' v-model="model.color_button" required)

                                .p-col-2
                                    div
                                        label.form-label Cor do fundo:
                                        ColorPicker(v-model="model.color_background")

                                    div(style="width: 196px;")
                                        .p-inputgroup.my-1
                                            span.p-inputgroup-addon #
                                            InputText.p-inputtext-lg(type='text' v-model="model.color_background" required)

                                .p-col-2
                                    div
                                        label.form-label Banner sobre o app?
                                        InputSwitch(v-model='show_mobile')

                            .p-col-6
                                label.form-label Imagem: *
                                FileUpload(
                                    name="contents",
                                    type="file",
                                    @select="uploadImagem",
                                    chooseLabel="Escolher Arquivo",
                                    url=''
                                    mode='basic'
                                    cancelLabel="Cancelar",
                                    accept="image/*",
                                    :maxFileSize="1000000"
                                )
                                    template(#empty)
                                        p Arraste e solte a imagem aqui para realizar upload
                                div(style="color: red; font-size: 12px;" v-if="hasImage === false") Escolha a imagem do Banner.
                                div(style="color: black; font-size: 12px;") Certifique-se de que o banner possui uma dimensão 1900x500.
                            .p-col-12
                                Panel(header="Preview Mobile (Modelo: iPhone 6/7/8 Plus)" :toggleable="true" :collapsed="true")
                                    .container-mobile.container
                                        nav
                                            div
                                                img(src='./../../assets/img/logo-medclub-login.png' style="width: 150px;")
                                                i.pi.pi-bars
                                        .preview-mobile.preview(:style="{'background-color': '#' + model.color_background}")
                                            div
                                                div
                                                    h1(:style="{color: '#' + model.color_button}" style="margin: 0") {{model.title}}
                                                    h2(:style="{color: '#' + model.color_button}" style="margin: 0") {{model.subtitle}}
                                                div(v-if="! model.show_mobile")
                                                    .button.button-default(:style="{'background-color': '#' + model.color_button, color: '#' + model.color_background}") {{model.text_button}}
                                                div(style="display: flex; flex-direction: column" v-else)
                                                    .button.button-app( :style="{'background-color': '#' + model.color_button, color: '#' + model.color_background}")
                                                        .jam.jam-apple
                                                        div
                                                            div Disponível na
                                                            div App Store
                                                    .button.button-app( :style="{'background-color': '#' + model.color_button, color: '#' + model.color_background}")
                                                        .jam.jam-google-play
                                                        div
                                                            div Disponível no
                                                            div Google Play

                                        .body-mobile
                                            div
                                                h1 Agende suas consultas, exames e cirurgias.


                            .p-col-12
                                Panel(header="Preview Desktop" :toggleable="true" :collapsed="true")
                                    .container-desktop.container
                                        nav
                                            div
                                                img(src='./../../assets/img/logo-medclub-login.png' style="width: 150px;")
                                                div Serviços
                                                div Como Funciona
                                                div Pergunte ao Doutor
                                                div Notícias
                                                div Locais de Atendimento
                                                div Dúvidas
                                                div Área do Doutor
                                        .preview-desktop.preview(:style="{'background-color': '#' + model.color_background}")
                                            // -
                                                img(:src="this.imagemBanner.data" alt="imagem do banner")
                                            div
                                                div
                                                    h1(:style="{color: '#' + model.color_button}" style="margin: 0") {{model.title}}
                                                    h2(:style="{color: '#' + model.color_button}" style="margin: 0") {{model.subtitle}}

                                                div(v-if="! model.show_mobile")
                                                    .button.button-default(:style="{'background-color': '#' + model.color_button, color: '#' + model.color_background}") {{model.text_button}}
                                                div(style="display:flex" v-else)
                                                    .button.button-app( :style="{'background-color': '#' + model.color_button, color: '#' + model.color_background}")
                                                        .jam.jam-apple
                                                        div
                                                            div Disponível na
                                                            div App Store
                                                    .button.button-app( :style="{'background-color': '#' + model.color_button, color: '#' + model.color_background}")
                                                        .jam.jam-google-play
                                                        div
                                                            div Disponível no
                                                            div Google Play
                                        .body-desktop
                                            div
                                                h1 Agende suas consultas, exames e cirurgias.

                            .p-col-12.ta-right
                                ProgressSpinner.waitingSalvar(v-if='waitingSalvar' strokeWidth='6')
                                Button(v-else label='Salvar' type="submit" style='max-width:160px')



</template>

<style lang="scss">
.preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div {
        h2 {
            filter: brightness(0.8)
        }
    }

    .button {
        border: 0;
        border-radius: 10px;
        font-weight: bold;
        text-align: center;
    }

    .button-default {
        display:inline-block;
    }
}

.container {
    border: 1px solid black;
    margin: 0px auto;

    nav {
        height: 80px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
}

.container-mobile {
    width: 414px;
    height: 736px;

    nav {
        & > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}

.preview-mobile {
    height: 40vh;
    align-items: flex-start;

    & > div {

        & > div{
            padding-left: 40px;
            padding-top: 15px;
            .button {
                padding: 15px;
            }
        }
    }
}

.container-desktop {
    width: 100%;
    height: 700px;

    nav {
        justify-content: center;

        & > div {
            display: flex;
            align-items: center;
            width: 1000px;

            & > * {
                margin-right: 20px;
            }
        }
    }
}

.preview-desktop {
    height: 400px;
    align-items: center;

    & > div {
        width: 1000px;

        & > div {
            padding: 5px;

            h1 {
                font-size: 3.5rem;
                font-weight: 400;
            }

            h2 {
                font-size: 40px;
                font-weight: bold;
            }

            .button {
                padding: 20px;
                font-size: 1.1rem;
                margin-top: 20px;
            }
        }
    }
}

.body-mobile {
    padding: 20px;
}

.body-desktop {
    display: flex;
    justify-content: center;

    div {
        width: 1000px;

        h1 {
            font-weight: 400;
        }
    }

}

.button-app {
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    margin: 10px;

    .jam {
        margin-right: 10px;
    }
}

.jam-apple {
    font-size: 30px !important;
}

.jam-google-play {
    font-size: 30px !important;
}

</style>

<script>
import ProgressBar from "primevue/progressbar"
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'
import ProgressSpinner from 'primevue/progressspinner'
import Panel from 'primevue/panel'
import Tooltip from "primevue/tooltip"
import ColorPicker from 'primevue/colorpicker';
import FileUpload from '../Noticias/FileUpload.vue';
import NovaAPI from "../../middleware/controllers/NovaAPI";
import Banners from "../../middleware/controllers/Banners";
import InputSwitch from 'primevue/inputswitch';

export default {
    data() {
        return {
            waiting: false,
            waitingSalvar: false,
            show_mobile: false,
            model: {
                title: 'Este é um título',
                subtitle: 'Este é um subtítulo',
                link: '',
                text_button: 'Saiba Mais',
                color_background: 'ff0000',
                color_button: 'ffffff',
                show_mobile: false
            },
            imagemBanner: {},
            tokenAPI: '',
            hasImage: ''
        }
    },
    components: { ProgressBar, Button, Dialog, Dropdown, InputText, ProgressSpinner, Panel, TextArea, ColorPicker, FileUpload, InputSwitch },
    directives: { tooltip: Tooltip },
    created() {
        this.waiting = true

        NovaAPI.login().then(result => {
            this.tokenAPI = result;

            if(this.$route.params.id !== undefined) {

                let id = 0

                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)

                Banners.getById(id, this.tokenAPI).then(response => {
                    this.model = response[0]

                    this.waiting = false;
                })
            } else {
                this.waiting = false
            }
        })
    },
    methods: {
        handleSubmit() {
            const action = this.model.id ? 'edit' : 'save'

            if(this.hasImage) {
                this.waitingSalvar = true

                NovaAPI.upload(
                    {
                        files: [
                            {
                                extension: this.imagemBanner.extensao,
                                destinationFolder: "site/banners",
                                file: this.imagemBanner.data,
                            },
                        ],
                    },
                    this.tokenAPI
                ).then(response => {

                    let showMobile = 0;
                    if (this.show_mobile == true)
                        showMobile = 1

                    let data = {}
                    if(action == 'edit') {
                        data = {
                            "attachments": {
                                "id": this.model.attachments.id,
                                "title": this.model.title,
                                "attach": 'https://' + response[0].url,
                                "sequence": 0
                            },
                            "id": this.model.id,
                            "title": this.model.title,
                            "subtitle": this.model.subtitle,
                            "link": this.model.link,
                            "text_button": this.model.text_button,
                            "color_button": this.model.color_button,
                            "color_background": this.model.color_background,
                            "show_mobile": showMobile
                        }
                    } else {
                        data = {
                            "attachments": {
                                "title": this.model.title,
                                "attach": 'https://' + response[0].url,
                                "sequence": 0
                            },
                            "title": this.model.title,
                            "subtitle": this.model.subtitle,
                            "link": this.model.link,
                            "text_button": this.model.text_button,
                            "color_button": this.model.color_button,
                            "color_background": this.model.color_background,
                            "show_mobile": showMobile
                        }
                    }

                    // if (action == 'edit')
                    //     data = {...data, id: this.model.id};
                    // if (action == 'save')
                    console.log(action);
                    Banners[action](data, this.tokenAPI)
                        .then(response => {
                            console.log('RESPONSE', response);
                            this.$toast.success("Salvo com sucesso.")
                            this.waiting = false;
                            this.waitingSalvar = false;
                            this.$router.push('/banners')
                        })
                })
            } else {
                this.hasImage = false;
            }
        },
        changeSwitch(event) {
            console.log(event);
           this.model.show_mobile = !this.model.show_mobile;

        },
        uploadImagem(event) {
            this.hasImage = true;

            var image = event.originalEvent.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);

            this.imagemBanner.extensao = image.name.split('.').pop();

            reader.onload = (e) => {
                this.imagemBanner.data = e.target.result;
            };
        }
    }
}
</script>
